import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h3>
          There are currently <span style={{ fontSize: 64 }}> 25 </span> blimps
          in the world
        </h3>
        <p style={{ fontSize: 18 }}>
          This is probably not accurate. This website is stupid.
        </p>
      </header>
    </div>
  );
}

export default App;
